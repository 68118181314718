import React, { PureComponent } from 'react'
import CheckLink from '../components/checkLink'

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    return (
      <li ref={this.container} className='xl:m-2 relative'>
        <button className='font-semibold uppercase text-2xl lg:text-lg block px-1 py-2 mt-1 text-gray-700 lg:hover:text-blue-400 border-b-2 border-transparent lg:hover:border-blue-400 flex flex-row items-center justify-center text-center w-full lg:w-auto lg:text-left focus:outline-none focus:ring ring-blue-400 ring-opacity-50' aria-haspopup='true' aria-expanded={this.state.subNavExpandedId === this.props.children ? 'true':'false'} onClick={() => this.toggleSubNav(`${this.props.children}`)} onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}>
          <span>{this.props.children}</span>
          <span>
            <svg xmlns='http://www.w3.org/2000/svg' className='w-5 -mt-1' height='1em'>
              <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
              <path d='M0 0h24v24H0z' fill='none'/>
            </svg>
          </span>
        </button>
        <ul className={`${this.state.subNavExpandedId === this.props.children ? 'block min-w-max md:absolute md:mt-2 md:p-3 md:shadow-md md:rounded md:right-0 flex flex-col bg-white':'hidden'}`}>
          {this.props.items.map((subNav, index) => {
            return (
              <li className='m-2 md:mx-0' key={`subNav-${index}`}>
                <CheckLink className='block py-2 px-4 xl:px-3 font-medium text-gray-800 text-2xl md:text-lg lg:text-base xl:text-lg text-center md:text-left transition duration-150 ease-in-out focus:outline-none focus:ring ring-blue-400 ring-opacity-50' activeClassName='lg:text-blue-400 border-white lg:border-blue-400' link={subNav.subnav_link}>{subNav.subnav_link_label}</CheckLink>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
