import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
// Components
import CheckLink from '../components/checkLink'
import EmblaCarousel from '../components/embla/emblaCarousel'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

let PageTemplate = props => {
  const pageData = props.data.prismicPage.data

  return (
    <Layout>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-gray-800 text-lg'>
        <div className='relative'>
          {pageData.page_header.length > 1 && (
            <EmblaCarousel autoplay={true} arrows={true} delayLength={6000} dots={false} loop={true} data={pageData}>
              {pageData.page_header.map((item, index) => {
                const sources = item.page_image.fluid
                return (
                  <div className={'relative max-h-screen-3/4 min-w-full' + (index === 0 ? ' active' : '')} key={`item-${index}`} aria-selected={(index === 0 ? 'true' : 'false')}>
                    <div className={`${pageData.page_header.[0].page_image.fluid && ('z-10 md:absolute inset-x-0 sm:mt-8 lg:mt-20 w-full p-4 lg:p-10 ml-auto mr-auto z-10 md:w-2/3 lg:w-1/2 md:flex flex-col text-center')} px-4 sm:px-8 md:px-10 bg-green-300'`}>
                      <div className='p-6 lg:p-10 bg-white bg-opacity-75'>
                        {item.page_title && (
                          <h1 className='z-10 relative text-4xl tracking-tight font-serif lg:text-5xl xl:text-6xl' dangerouslySetInnerHTML={{__html:item.page_title.text}} />
                        )}
                        {item.page_text && (
                          <div className={`z-10 relative mt-3 md:mt-5 prose prose-xl ${pageData.page_header.[0].page_image.fluid ? ('text-gray-600'):('mx-auto md:max-w-3xl')}`} dangerouslySetInnerHTML={{__html:item.page_text.html}} />
                        )}
                        {item.page_button_link.url && (
                          <div className='z-10 relative mt-auto pt-3 md:pt-6'>
                            <CheckLink className={`${item.page_button_text && ('button')}`} link={item.page_button_link}>{item.page_button_text}</CheckLink>
                          </div>
                        )}
                      </div>
                    </div>
                    {item.page_image && (
                      <Img fluid={sources} alt={item.page_image.alt || ''} />
                    )}
                  </div>
                )
              })}
            </EmblaCarousel>
          )}
          {pageData.page_header.length === 1 && (
            <div className={`${pageData.page_header.[0].page_image.fluid ? ('relative bg-white overflow-hidden md:py-16 sm:py-16 component-hero'):('')}`}>
              {pageData.page_header !== null && pageData.page_header.[0].page_image.fluid && (
                <div className='md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
                  <Img className='w-full h-full object-cover' fluid={pageData.page_header.[0].page_image.fluid} alt={pageData.page_header.[0].page_image.alt || ''} />
                </div>
              )}
              <div className={`${pageData.page_header.[0].page_image.fluid ? ('lg:px-10 md:pb-20 md:pt-16 relative xl:py-24 md:text-left'):('')} z-10 relative max-w-6xl mx-auto py-8 px-4 sm:px-6 text-center`}>
                <div className={`${pageData.page_header.[0].page_image.fluid ? ('md:w-1/2 md:pr-8'):('')}`}>
                  {pageData.page_header.[0].page_title && (
                    <h1 className='text-4xl tracking-tight font-serif md:text-5xl xl:text-6xl' dangerouslySetInnerHTML={{__html:pageData.page_header.[0].page_title.text}} />
                  )}
                  {pageData.page_header.[0].page_text && (
                    <div className={`z-10 relative mt-3 md:mt-5 prose prose-lg lg:prose-xl prose-blue ${pageData.page_header.[0].page_image.fluid ? (''):('min-w-full')} `} dangerouslySetInnerHTML={{__html:pageData.page_header.[0].page_text.html}} />
                  )}
                  {pageData.page_header.[0].page_button_link.url && (
                    <div className='mt-auto pt-3 md:pt-6'>
                      <CheckLink className={`${pageData.page_header.[0].page_button_text && ('button')}`} link={pageData.page_header.[0].page_button_link}>{pageData.page_header.[0].page_button_text}</CheckLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
          return (
            <div className='md:pt-6 relative z-10' key={`slice-${index}`}>
              <SliceList slice={slice} />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default withPreview(PageTemplate)
