import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import {StaticQuery, graphql } from 'gatsby'
import CheckLink from '../components/checkLink'

const socialQuery = graphql`
  {
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    youtube: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    github: file(relativePath: { eq: "github.png" }) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Social = ({ data, networks }) => {
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={data => (

        <div className='flex space-x-6 md:order-2 justify-center sm:justify-start'>
          {networks.social.map((social, index) => {
            var socialImage = data[social.social_network.toLowerCase()].childImageSharp.fixed
            return (
              <CheckLink className='transform hover:scale-105 rounded-full focus:outline-none focus:ring ring-blue-400 ring-opacity-50' link={social.social_network_url} key={`social-${index}`}>
                <Img fixed={socialImage} alt={social.social_network} loading='lazy' />
                <span className="sr-only">{social.social_network}</span>
              </CheckLink>
            )
          })}
        </div>
      )}
    />
  )
}

Social.propTypes = {
  networks: PropTypes.object.isRequired,
}

export default Social
