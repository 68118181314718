import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'


const BannerWithCaption = ({ slice }) => {
  var position = 'left'
  if (slice.primary.image_position === true) {
    position = 'right'
  }
  return (
    <div className={`component-${slice.slice_type} relative overflow-hidden bg-white shadow-md ${position}`}>
      <div className={`h-56 sm:h-72 relative md:absolute ${position}-0 md:h-full md:w-1/2 overflow-hidden ${position === 'left' ? ('rounded-l-md'):('rounded-r-md')}`}>
        <Img className='w-full h-full object-cover' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
        {slice.primary.image.copyright && <span className='absolute bottom-0 right-0 text-white bg-black opacity-50 m-2 px-2 py-1 text-xs rounded-sm'>{slice.primary.image.copyright}</span>}
      </div>
      <div className='relative max-w-7xl mx-auto py-8 md:py-12 lg:py-16'>
        <div className={`${position === 'left' ? 'md:ml-auto md:pr-6' : 'md:mr-auto md:pl-6'} md:w-1/2 px-6`}>
          <div className='prose prose-lg lg:prose-xl prose-blue'>
            {slice.primary.title.text && (
              <h2 id={slice.primary.title.text.split(' ').join('-').toLowerCase()} className='font-medium pb-2 mt-0'>{slice.primary.title.text}</h2>
            )}
            {slice.primary.intro && (
              <p id={slice.primary.intro.split(' ').join('-').toLowerCase()} className='mt-2 text-3xl font-bold sm:text-4xl'>
                {slice.primary.intro}
              </p>
            )}
            {slice.primary.text.html && (
              <div className='mt-6 text-gray-600 prose prose-lg lg:prose-xl prose-blue' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
            )}
          </div>
          {slice.primary.button_link.url && (
            <div className='mt-6'>
              <div className='inline-flex'>
                <CheckLink className='button text-xl bg-transparent text-white border-white hover:border-white hover:bg-blue-600' link={slice.primary.button_link}>
                  {slice.primary.button_text}
                </CheckLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
