import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  var numberOfCards = slice.items.length
  var gridClass = ''
  if (numberOfCards > 4) {
    gridClass = 'md:grid-cols-4'
  } else if (numberOfCards === 4) {
    gridClass = 'md:grid-cols-2 lg:grid-cols-4'
  } else {
    gridClass = 'md:grid-cols-' + numberOfCards
  }
  return (
    <div className={`component-${slice.slice_type} mx-auto`}>
      {slice.primary.deck_title && (
        <div className='prose prose-lg lg:prose-xl prose-blue text-center max-w-none mb-10' dangerouslySetInnerHTML={{__html:slice.primary.deck_title.html}} />
      )}
      <ul className={`grid grid-cols-1 gap-5 ${gridClass}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} key={`card-${index}`} />
          )
        })}
      </ul>
    </div>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
