import React from 'react'
import PropTypes from 'prop-types'

const Accordion = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      <div className='prose prose-lg lg:prose-xl prose-blue text-center max-w-screen-2xl mx-auto'>
        {slice.primary.title && (
          <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
        )}
        {slice.primary.text && (
          <div dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
        )}
      </div>
      <ul className='mt-12 max-w-4xl mx-auto'>
        {slice.items.map((accordion, index) => {
          return (
            <li key={`accordion-${index}`}>
              <details className='bg-white rounded-md shadow-sm transform transition-all mb-3' itemScope itemProp='mainEntity' itemType='https://schema.org/Question'>
                <summary className='cursor-pointer bg-white text-blue-500 hover:text-blue-500 m-0 text-xl block cursor-pointer font-serif w-full text-left p-4 focus:outline-none focus:ring ring-blue-500 ring-opacity-50 rounded'>
                  <div className='flex-1' itemProp='name' dangerouslySetInnerHTML={{__html:accordion.question.html}}/>
                </summary>
                <div className='border-t p-5' itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer'>
                  <div className='prose prose-lg lg:prose-xl prose-blue min-w-full' itemProp='text' dangerouslySetInnerHTML={{__html:accordion.answer.html}} />
                </div>
              </details>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Accordion.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Accordion
