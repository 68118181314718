import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import CheckLink from '../components/checkLink'
import Social from '../components/social'

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicSocial {
      ...PrismicSocialFragment
    }
    logo: file(relativePath: { eq: "companyLogo.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

class Footer extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={`${footerQuery}`}
        render={data => {
          const footerData = data.prismicNavigation.data
          const socialData = data.prismicSocial.data
          return (
            <>
            <footer role='banner' className='relative bg-white shadow-md border-t mt-auto'>
              <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8  text-center md:text-left'>
                  <div className='md:col-span-1 mb-8'>
                    <Img fixed={data.logo.childImageSharp.fixed} alt={`${footerData.company_display_name} logo`} />
                    <div dangerouslySetInnerHTML={{__html:footerData.company_address.html}} />
                    {footerData.company_phone && (
                      <div className='mt-4'>T: <a href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a></div>
                    )}
                  </div>

                  <div className='md:col-span-1 mb-8'>
                    {footerData.body.map((footer, index) => {
                      return (
                        <div className='px-3' key={`footer-${index}`}>
                          <div className='mb-4 uppercase text-gray-700' dangerouslySetInnerHTML={{__html:footer.primary.title}} />
                          <ul className='mt-4 space-y-4'>
                          {footer.items.map((footerItem, index) => {
                            return (
                              <li className='inline-block w-1/2 md:w-full md:block hover:text-blue-400 border-transparent hover:border-blue-400' key={`footerItem-${index}`}>
                                <CheckLink className='border-b focus:outline-none focus:ring ring-blue-400 ring-opacity-50' link={footerItem.link}>{footerItem.link_label}</CheckLink>
                              </li>
                            )
                          })}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                  <div className='md:col-span-1 mb-8'>
                    <div className='mb-4 uppercase text-gray-700'>Follow Us</div>
                    <div className='flex mt-4 justify-center lg:justify-start'>
                      <Social networks={socialData} />
                    </div>
                  </div>
                </div>
                <div className='mt-12 border-t border-gray-200 pt-8'>
                  <p className='text-base text-center'>{footerData.company_display_name} &copy; {year}. All rights reserved.</p>
                  <p className='text-base leading-6 text-gray-500 text-center'>Made with <span role='img' aria-label='Heart emoji'>❤️</span> by <a className='text-purple-700 focus:outline-none focus:ring ring-blue-400 ring-opacity-50' href="https://www.thejuniperstudio.com/" target="_blank" rel="noopener noreferrer">The Juniper Studio</a></p>
                </div>
              </div>
            </footer>
            </>
          )
        }}
      />
    )
  }
}

export default Footer
