import React from 'react'
import PropTypes from 'prop-types'


const Video = ({ slice }) => {
  if (slice.primary.video !== null) {
    var padding = {
      paddingBottom: 100 / (slice.primary.video.width / slice.primary.video.height) + '%'
    }
    var embed = slice.primary.video.embed_url
    if (embed !== null && embed.includes('watch')) {
      embed = 'https://www.youtube.com/embed/' + embed.split('v=')[1]
      var ampersandPosition = embed.indexOf('&')
      if(ampersandPosition !== -1) {
        embed = embed.substring(0, ampersandPosition)
      }
    }
  }
  return (
    <React.Fragment>
      {slice.primary.title && (
        <div className='prose prose-lg lg:prose-xl prose-blue text-center max-w-none mb-10' dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
      )}
      {slice.primary.video.embed_url ? (
        <div className='relative' style={padding}>
          <iframe className='absolute inset-0' width='100%' height='100%' title={slice.primary.video.title} src={`${embed}?feature=oembed`} allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
        </div>
      ) : (
        <>
          {slice.primary.video_internal.url !== '' ? (
            <div className='video-embed'>
              <video controls poster={slice.primary.video_poster.fluid.srcSetWebp}>
                <source src={slice.primary.video_internal.url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          ):(
            <strong>No video available</strong>
          )}
        </>
      )}
    </React.Fragment>
  )
}

Video.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Video
