import React, { PureComponent } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import CheckLink from '../components/checkLink'
import Dropdown from '../components/dropdown'

const headerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    logo: file(relativePath: { eq: "companyLogo.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

class Header extends PureComponent {

  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
      subNavExpanded: false
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={data => {
          const headerData = data.prismicNavigation.data
          return (
            <header role='banner' className='z-20 fixed inset-x-0 top-0 w-full bg-white shadow-md border-t-4 border-blue-400'>
              <nav id='navigation' className='max-w-screen-xl mx-auto flex items-center justify-between flex-wrap px-2 sm:p-4 relative'>
                <Link to='/' className='relative z-20 w-full py-2 mr-5 focus:outline-none focus:ring ring-blue-400 ring-opacity-50' style={{maxWidth:230}}>
                  <Img className='w-auto' fluid={data.logo.childImageSharp.fluid} alt={`${headerData.company_display_name} logo`} />
                </Link>
                <span className='lg:hidden z-20'>
                  <Hamburger
                    color='#60A5FA'
                    label='Show menu'
                    rounded
                    toggled={this.state.mainNavExpanded}
                    toggle={this.toggleMainNav}
                  />
                </span>

                <ul className={`${this.state.mainNavExpanded === false ? ('hidden'):('absolute inset-0 bg-white flex flex-col h-screen justify-center z-10')} lg:flex lg:justify-between xl:justify-end lg:items-center`}>
                  {headerData.nav.map((nav, index) => {
                    return (
                      <React.Fragment key={`nav-${index}`}>
                        {nav.items.length > 1 ? (
                          <Dropdown items={nav.items}>{nav.primary.label}</Dropdown>
                        ):(
                          <li key={`nav-${index}`} className='lg:m-2'>
                            <CheckLink className='font-semibold uppercase text-2xl lg:text-lg block px-1 py-2 mt-1 text-gray-700 lg:hover:text-blue-400 border-b-2 border-transparent lg:hover:border-blue-500 text-center lg:text-left focus:outline-none focus:ring ring-blue-400 ring-opacity-50' activeClassName='lg:text-blue-400 border-white lg:border-blue-400' link={nav.primary.link} onClick={ this.toggleNav }>{nav.primary.label}</CheckLink>
                          </li>
                        )}
                      </React.Fragment>
                    )
                  })}
                </ul>
              </nav>
            </header>
          )
        }}
      />
    )
  }
}

export default Header
