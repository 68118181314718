import React from 'react'
import PropTypes from 'prop-types'
// Components
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Table from './table'
import Text from './text'
import Video from './video'

const SliceList = ({ slice }) => {
  return (
    <>
      {slice.slice_type === 'accordion' && (
        <div className='w-full max-w-screen-2xl mx-auto px-5 pb-5'>
          <Accordion slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='relative z-0 py-5'>
          <div className='z-10 relative w-full max-w-screen-2xl mx-auto px-5 pb-5'>
            <Contact slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'image_gallery' && (
        <div className='max-w-4xl mx-auto mx-auto px-5 pb-5'>
          <Image autoplay delayLength={6000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'map' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <Map slice={slice} />
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='quote'>
          <div className='w-full max-w-screen-2xl mx-auto px-5 py-8 md:py-16'>
            <Quote slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'table' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <Table slice={slice} />
        </div>
      )}
      {slice.slice_type === 'text' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <Text slice={slice} />
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-10 relative'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  habitat: PropTypes.string,
  slice: PropTypes.object.isRequired,
}

export default SliceList
