import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Quote = ({ slice }) => {
  return (
    <blockquote className={`component-${slice.slice_type} text-center px-10 md:flex-grow md:flex md:flex-col max-w-screen-2xl mx-auto relative text-blue-50`}>
      <div className='text-2xl md:text-3xl font-medium md:flex-grow'>
        <span className='relative z-10 my-4 text-2xl md:text-3xl lg:text-4xl relative leading-tight font-serif italic'>{slice.primary.quote.text}</span>
      </div>
      <footer className='flex items-center justify-center mt-8 mb-3'>
        {slice.primary.image.fluid && (
          <div className='flex-shrink-0 inline-flex rounded-full border-2 border-white mr-4'>
            <div className='overflow-hidden transition duration-500 transform'>
              <Img className='h-24 w-24 rounded-full bg-gray-100' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
            </div>
          </div>
        )}
        <div className={`${slice.primary.image.fluid ? ('text-left'):('text-center')}`}>
          <p className='text-xl font-medium'>{slice.primary.author}</p>
          <p className='text-base font-medium'>
            {slice.primary.role &&
              <span>{slice.primary.role}
            </span>}
            {slice.primary.company && slice.primary.role && ','}
            {slice.primary.company && <span> {slice.primary.company}</span>}</p>
        </div>
      </footer>
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
