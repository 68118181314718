import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent';
import Header from './header'
import Footer from './footer'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='subpixel-antialiased flex flex-col min-h-screen bg-gray-50'>
          <a href='#main' className='sr-only focus:not-sr-only'>Skip to main content</a>
          <Header />
          <main role='main' id='main' className='mt-24 pt-1 md:mt-32 md:pt-0 pb-10'>{props.children}</main>
          <Footer />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='button'
            declineButtonClasses='mx-1 px-4 py-2 inline-block'
            containerClasses='fixed inset-x-0 text-white bg-gray-900 p-3 text-sm text-center z-30 text-lg'
            contentClasses='inline-block mb-2'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            New Romney Country Fayre uses cookies to improve their digital experience. For more infomration please view our <Link to='/privacy-policy' className='underline focus:outline-none focus:ring ring-blue-400 ring-opacity-50'>Privacy policy</Link>
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.object,
  social: PropTypes.object,
}

export default Layout
