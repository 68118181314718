import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

const Table = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} mt-2`}>
      {slice.items.map((table, index) => {
        return (
          <div className='mt-2 overflow-x-auto' key={`table-${index}`}>
            <ReactMarkdown
              plugins={[gfm]}
              children={table.markdown.text}
            />
          </div>
        )
      })}
    </div>
  )
}

Table.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Table
