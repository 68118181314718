import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import CheckLink from './checkLink'


const Text = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} ${slice.primary.columns === true && 'columns'}`}>
      <div className={`prose prose-lg lg:prose-xl prose-blue mx-auto ${slice.primary.columns === false && 'max-w-screen-2xl'}`} dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      {(slice.primary.button_link.url && slice.primary.button_text) &&
        <div className='flex justify-center max-w-6xl mt-5 mx-auto'>
          <CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
        </div>
      }
    </div>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
