import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {

  return (
    <li className={`component-card flex flex-col rounded-md shadow-md overflow-hidden ${fields.button_link.url && ('relative transform transition duration-300 ease hover:scale-105 hover:shadow-lg')}`}>
      {get(fields.image, 'fluid') && (
        <div className='flex-shrink-0 relative'>
          <Img fluid={fields.image.fluid} alt={fields.image.alt} />
          {fields.image.copyright && <span className='absolute bottom-0 right-0 text-white bg-black opacity-50 m-2 px-2 py-1 text-xs rounded-sm'>{fields.image.copyright}</span>}
        </div>
      )}
      <div className='flex-1 bg-white p-6 flex flex-col'>
        {get(fields, 'card_title') && <div className='text-lg font-medium pb-2'>{get(fields, 'card_title')}</div>}
        {get(fields, 'intro') && <div className='text-2xl font-semibold text-gray-900'>{get(fields, 'intro')}</div>}
        {get(fields, 'text.html') && <div className='mt-3 text-lg text-gray-500 prose prose-lg lg:prose-xl prose-blue' dangerouslySetInnerHTML={{__html:get(fields, 'text.html')}} />}
        {fields.button_link.url && (
          <CheckLink className='link block mt-auto pt-4 focus:outline-none focus:ring ring-blue-400 ring-opacity-50' link={fields.button_link}>{fields.button_text} <span className='inline-block transform transition duration-150 hover:translate-x-full'>→</span></CheckLink>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
